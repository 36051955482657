(
    function (global, $, Si) {
        "use strict";

        Si.when(['Helpers']).then(slickComponent);

        function slickComponent(Helpers) {

            Si.component('Slick', {
                wrap: wrap
            });

            function wrap(element, options, createBreakpoint, globalElement) {

                var resizeDelayer = null,
                    slicked = false;
                element = $(element);
                options = Helpers.merge(
                    {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        slide: '.slide',
                        prevArrow: '<button type="button" class="slick-prev">' +
                                   '<i class="icon-arrow-left"></i>' +
                                   '</button>',
                        nextArrow: '<button type="button" class="slick-next">' +
                                   '<i class="icon-arrow-right"></i>' +
                                   '</button>',
                        responsive: [
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1
                                }
                            }
                        ]
                    }, options || {}
                );
                createBreakpoint = createBreakpoint === false ? null : +(
                    createBreakpoint || 992
                );
                globalElement = globalElement ? $(globalElement) : $(global);

                if (createBreakpoint) {
                    bindResizeEvent();
                    onResize();
                } else {
                    createSlick();
                }

                function bindResizeEvent() {

                    globalElement.on(
                        'resize', function () {
                            if (resizeDelayer) {
                                clearTimeout(resizeDelayer);
                            }
                            resizeDelayer = setTimeout(onResize, 300);
                        }
                    );
                }

                function onResize() {
                    var globalWidth = globalElement.width();

                    if (globalWidth < createBreakpoint) {
                        if (!slicked) {
                            createSlick();
                        }
                    } else if (slicked) {
                        destroySlick();
                    }
                }

                function createSlick() {
                    element.slick(options);
                    slicked = true;
                }

                function destroySlick() {
                    element.slick('unslick');
                    slicked = false;
                }
            }
        }
    }
)(window, jQuery, Si);