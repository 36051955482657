(
    function (global, Si) {
        "use strict";

        Si.when(['Ajax']).then(matchesAtDateAction);

        function matchesAtDateAction(Ajax) {
            var MatchesAtDate = Ajax.action('matches-at-date', {});

            Si.component('Ajax/MatchesAtDate', MatchesAtDate);
        }
    }
)(window, Si);