(
    function (global, Si) {
        "use strict";

        Si.when(['Ajax']).then(guideTourAction);

        function guideTourAction(Ajax) {
            var guideTour = Ajax.action('check-guide', {});

            Si.component('Ajax/guideTour', guideTour);
        }
    }
)(window, Si);

