(function (global, $, Si) {
        "use strict";

        Si.when('Ajax/MatchesAtDate', 'Templates', 'Helpers', 'Slick', 'TableSorter', 'Ajax/guideTour').then(indexPage);

        function indexPage(MatchesAtDate, Templates, Helpers, Slick, TableSorter, guideTour) {

            initNews();
            initMatchList();
            notifyMessageToggle();

            $('[data-match-list]').on('click', '.league-link', function (e) {
                e.stopPropagation();
            });
            $(document).on('click', '[data-mobile-day-link]', function (e) {
                e.preventDefault();
                var day = $(this).data('mobileDayLink');
                var tourid = $(this).data('mobileDayLinkTourid');

                $('.mobile-tour-'+tourid).find('.circle').removeClass('active');
                $(this).addClass('active');
                $('.mobile-match-list-day-block-'+tourid).hide();
                $('#day_' + day+'_'+tourid).show();
            });


            $("#video-link").click(function () {
                guideTour.get();
                var $this = $(this);
                var $iframe = $("<iframe>").attr("src", $this.data("link")).css({"width": 400, "height": 300});
                var $title = $("<h1>").text($this.data("title"));
                $("#video-view").html($title).append($iframe);
                $iframe.wrap("<div class='class-video'>");
                $('#guided-tour-modal').modal('show');
            });

            $('#guided-tour-modal').on('hidden.bs.modal', function () {
                $("#video-view").html('');
            });

            function notifyMessageToggle() {
                var holder = $('.notify-message'),
                    close = holder.find('.close-window'),
                    activeClass = 'notify-active';

                guideTour.get({check: true}).then(function (response) {
                    if (response !== 1) {
                        setTimeout(function () {
                            $('body').addClass(activeClass); // show message on pageload + 1000ms
                        }, 1000);
                    }
                });

                close.on('click', function (e) { // close handler
                    e.preventDefault();
                    guideTour.get();
                    $('body').removeClass(activeClass);
                });
            }

            function initNews() {

                var $newsBlock = $('#latest_news');
                if ($newsBlock.length) {
                    Slick.wrap($newsBlock);
                }
            }


            function initMatchList() {
                var $triggers = $('[data-match-list-constraint]'),
                    $defaultTrigger = $($triggers.filter('[data-default]').get(0) || $triggers.get(0)),
                    $listHolder = $('[data-match-list]');

                loadForTrigger($defaultTrigger);

                $triggers.click(triggerClickHandler);

                function triggerClickHandler(event) {

                    var $trigger = $(this);

                    loadForTrigger($trigger);

                    return event.preventDefault();
                }

                function loadForTrigger($trigger) {

                    highlighTrigger($trigger);

                    loadMatches($trigger.data('match-list-constraint'));
                }

                function loadMatches(constraints) {

                    //@todo maybe add another loader, loadmask on parent block or just spinner somewhere and do not clear old list until new one ready
                    updateList(Templates.make('loader.mustache'));

                    MatchesAtDate.get(constraints).then(
                        function (response) {
                            var data = processResponse(response);

                            data.lower = function () {
                                return function (text, render) {
                                    //wrong line return render(text.toLowerCase());
                                    return render(text).toLowerCase();
                                }
                            };
                            data.user_is_guest = response.user_is_guest;
                            updateList(Templates.make('match/list.mustache', data));

                        }, function (status, error) {
                            updateList(Templates.make('match/list.mustache', []));
                        }
                    );
                }

                function processResponseForMobile(response) {
                    if (!response.tournaments.length) {
                        return [];
                    }
                    var sorted_tournaments = response.tournaments.sort(tournamentPositionSorter);

                    var tournaments_country_separated = [];
                    for (var i in sorted_tournaments) {
                        if (sorted_tournaments[i].country == '') {
                            tournaments_country_separated.push(sorted_tournaments[i]);
                        }
                    }
                    for (var i in sorted_tournaments) {
                        if (sorted_tournaments[i].country != '') {
                            tournaments_country_separated.push(sorted_tournaments[i]);
                        }
                    }
                    response.tournaments = tournaments_country_separated;

                    $.each(response.tournaments, function (i1, tournament) {
                        $.each(tournament.days, function (i2, day) {
                            $.each(day.matches, function (i3, match) {
                                response.tournaments[i1].days[i2].matches[i3] = fillMatchData(match);
                            });
                        });
                    });
                    return response;
                }

                function processResponse(response) {
                    if (!response.tournaments.length || !response.matches.length) {
                        return [];
                    }

                    var mapTournaments = {};

                    $.each(response.tournaments, function (i, tournament) {
                        mapTournaments[Helpers.strval(tournament.id)] = tournament;
                        tournament.matches = [];
                    });
                    $.each(response.matches, function (i, match) {

                        if (mapTournaments.hasOwnProperty(match.tournamentid)) {
                            mapTournaments[match.tournamentid].matches.push(fillMatchData(match));
                        }
                    });
                    var sorted_tournaments = response.tournaments.sort(tournamentPositionSorter);

                    var tournaments_country_separated = [];
                    for (var i in sorted_tournaments) {
                        if (sorted_tournaments[i].country == '') {
                            tournaments_country_separated.push(sorted_tournaments[i]);
                        }
                    }
                    for (var i in sorted_tournaments) {
                        if (sorted_tournaments[i].country != '') {
                            tournaments_country_separated.push(sorted_tournaments[i]);
                        }
                    }
                    return tournaments_country_separated;
                }

                function tournamentPositionSorter(t1, t2) {

                    return t1.position - t2.position;
                    // return (t1.country != '' && t2.country == '') || (t1.country == '' && t2.country == '') ? 1 : (t1.position > t2.position) ? -1 : 0;
                }

                function fillMatchData(match) {
                    match.kickoff_display = Helpers.formatServerDateTime(match.kickoff_server, false, false, "D MMM YYYY");
                    match.kickoff_display_hours = Helpers.formatServerDateTime(match.kickoff_server, false, false, "HH:mm");
                    // match.kickoff_display = match.kickoff_server;
                    var lastUpdateMoment = null;
                    if (match.report) {

                        match.report = fillReportData(match.report);

                        lastUpdateMoment = Helpers.serverDateTimeToMoment(match.report.modifieddate);

                        /*match.report.modifieddate = Helpers.formatServerDateTime(match.report.modifieddate, false, false, "D MMM YYYY");
                        match.report.modifieddate_hours = Helpers.formatServerDateTime(match.report.modifieddate, false, false, "HH:mm");*/
                        match.report.modifieddate = lastUpdateMoment.format("D MMM YYYY");
                        match.report.modifieddate_hours = lastUpdateMoment.format("HH:mm");
                    }
                    if (match.preview) {

                        var lastPreviewHomeUpdateMoment = null,
                            lastPreviewAwayUpdateMoment = null;
                        if (match.preview.homemodifieddate) {

                            lastPreviewHomeUpdateMoment = Helpers.serverDateTimeToMoment(
                                match.preview.homemodifieddate);
                        }
                        if (match.preview.awaymodifieddate) {

                            lastPreviewAwayUpdateMoment = Helpers.serverDateTimeToMoment(
                                match.preview.awaymodifieddate);
                        }

                        lastUpdateMoment = Helpers.latestMoment(lastUpdateMoment, lastPreviewHomeUpdateMoment,
                                                                lastPreviewAwayUpdateMoment);
                    }
                    match.last_update_date = lastUpdateMoment ? lastUpdateMoment.format("D MMM YYYY") : null;
                    match.last_update_hours = lastUpdateMoment ? lastUpdateMoment.format("HH:mm") : null;

                    return match;
                }

                function fillReportData(report) {
                    report.score = {};
                    report.score.home = report.homescore_aet || report.homescore;
                    report.score.away = report.awayscore_aet || report.awayscore;
                    report.score.aet = !!(report.homescore_aet || report.awayscore_aet);
                    if (report.homescore_ap || report.awayscore_ap) {
                        report.score.ap = {
                            home: report.homescore_ap || '-',
                            away: report.awayscore_ap || '-'
                        };
                    }

                    return report;
                }

                function highlighTrigger($trigger) {
                    $triggers.removeClass('active');
                    $trigger.addClass('active');
                }

                function updateList(content) {

                    $listHolder.empty();
                    $listHolder.append(content);
                    TableSorter.forTable($listHolder.find('.tournament-matches'));
                }

            }
        }
    })(window, jQuery, Si);
