//@todo finish this
(
    function (global, $, Si) {
        "use strict";

        Si.when(['Helpers']).then(tableSorterComponent);

        function tableSorterComponent(Helpers) {

            Si.component('TableSorter', {
                forTable: forTable
            });

            function forTable(table, options) {

                table = $(table);
                options = Helpers.merge(
                    {
                        paging: false,
                        searching: false,
                        autoWidth: false,
                        info: false,
                        order: [],
                        aoColumnDefs: [
                            {
                                bSortable: false,
                                aTargets: ["no-sort"]
                            }
                        ]
                    }, options || {}
                );

                table.DataTable(options);
            }
        }
    }
)(window, jQuery, Si);